import { useTranslation } from "react-i18next";
import StatisticsReportsHeader from "./StatisticsReportsHeader";
import { useStatistics } from "./hooks/useStatistics";
import { useSelector } from "react-redux";
import {
  getBeachChairReportEndDateSelector,
  getBeachChairReportStartDateSelector,
} from "../../../store/selectors/reportSelectors";
import React, { useCallback, useMemo } from "react";
import { Chart, AxisOptions } from "react-charts";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBCircularProgress from "../../SBComponents/SBForms/SBCircularProgress";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import SBLabel from "../../SBComponents/SBForms/SBLabel";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBSelectListSubHeader from "../../SBComponents/SBForms/SBSelectListSubHeader";
import GridOnIcon from "@mui/icons-material/GridOn";
import { useGetSectionByVendorId } from "./hooks/useGetSectionByVendorId";
import "dayjs/locale/de";
import dayjs from "dayjs";
dayjs.locale("de");
type DailyStars = {
  id: number;
  date: Date;
  stat: number;
};

const StatisticsReports = () => {
  const { t } = useTranslation(["common"]);
  const startDate = useSelector(getBeachChairReportStartDateSelector);
  const endDate = useSelector(getBeachChairReportEndDateSelector);
  const { data } = useStatistics(startDate, endDate);
  const [value, setValue] = React.useState<string>("0");
  const { data: sections } = useGetSectionByVendorId();

  const primaryAxis = React.useMemo(
    (): AxisOptions<DailyStars> => ({
      getValue: (datum) => datum.date,
      shouldNice: false,
      formatters: {
        scale: (value, _) => `${dayjs(value).format("DD-MMMM")}`,
      },
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.stat,
        elementType: "line",
      },
    ],
    []
  );

  const secondaryAxesTotalChairs = React.useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.stat,
        elementType: "area",
      },
    ],
    []
  );

  const secondaryAxesUtilization = React.useMemo(
    (): AxisOptions<DailyStars>[] => [
      {
        getValue: (datum) => datum.stat,
        elementType: "area",
        formatters: {
          scale: (value, _) => `${value} %`,
        },
      },
    ],
    []
  );

  const handleChange = useCallback((event: SelectChangeEvent) => {
    const value = event.target.value;
    setValue(value);
  }, []);

  const chairs = useMemo(
    () =>
      data
        ?.flatMap((stat) => [
          {
            id: stat.sectionId,
            label: `${
              sections?.items?.filter(
                (section) => section.id === stat.sectionId
              )[0]?.name ?? ""
            } ${t("confirmedChairs")}`,
            data: stat.stats.flatMap((statistic) => ({
              date: new Date(statistic.date),
              stat: statistic.confirmedChairs,
            })),
          },
          {
            id: stat.sectionId,
            label: `${
              sections?.items?.filter(
                (section) => section.id === stat.sectionId
              )[0]?.name ?? ""
            } ${t("pendingChairs")}`,
            data: stat.stats.flatMap((statistic) => ({
              date: new Date(statistic.date),
              stat: statistic.pendingChairs,
            })),
          },
        ])
        .filter((item) => (value === "0" ? true : item.id === +value)) || [],
    [data, value, sections, t]
  );
  const revenue = useMemo(
    () =>
      data
        ?.flatMap((stat) => [
          {
            id: stat.sectionId,
            label: `${
              sections?.items?.filter(
                (section) => section.id === stat.sectionId
              )[0]?.name ?? ""
            } ${t("confirmedRevenue")}`,
            data: stat.stats.flatMap((statistic) => ({
              date: new Date(statistic.date),
              stat: +statistic.confirmedRevenue,
            })),
          },
          {
            id: stat.sectionId,
            label: `${
              sections?.items?.filter(
                (section) => section.id === stat.sectionId
              )[0]?.name ?? ""
            } ${t("pendingRevenue")}`,
            data: stat.stats.flatMap((statistic) => ({
              date: new Date(statistic.date),
              stat: +statistic.pendingRevenue,
            })),
          },
        ])
        .filter((item) => (value === "0" ? true : item.id === +value)) || [],
    [data, value, sections, t]
  );

  const totalChairs = useMemo(
    () =>
      data
        ?.flatMap((stat) => ({
          id: stat.sectionId,
          label: `${
            sections?.items?.filter(
              (section) => section.id === stat.sectionId
            )[0]?.name ?? ""
          }`,
          data: stat.stats.flatMap((statistic) => ({
            date: new Date(statistic.date),
            stat: +statistic.totalChairs,
          })),
        }))
        .filter((item) => (value === "0" ? true : item.id === +value)) || [],
    [data, value, sections, t]
  );

  const utilization = useMemo(() => {
    const dates: { date: Date; stat: number }[] = [];
    if (value === "0") {
      const dateMap = new Map();
      data?.forEach((stat) => {
        stat.stats.forEach((statistics) => {
          const totalChairs =
            (dateMap?.get(statistics.date)?.totalChairs ?? 0) +
            statistics.totalChairs;
          const confirmedChairs =
            (dateMap?.get(statistics.date)?.confirmedChairs ?? 0) +
            statistics.confirmedChairs;
          dateMap.set(statistics.date, { totalChairs, confirmedChairs });
        });
      });
      dateMap.forEach((value, key) => {
        dates.push({
          date: new Date(key),
          stat: Math.round(
            (value.confirmedChairs / value.totalChairs + Number.EPSILON) * 100
          ),
        });
      });
    }

    return value === "0"
      ? [
          {
            id: 0,
            label: `${t("utilization")}`,
            data: dates,
          },
        ]
      : data
          ?.flatMap((stat) => ({
            id: stat.sectionId,
            label: `${
              sections?.items?.filter(
                (section) => section.id === stat.sectionId
              )[0]?.name ?? ""
            }`,
            data: stat.stats.flatMap((statistic) => ({
              date: new Date(statistic.date),
              stat: Math.round(
                (statistic.confirmedChairs / statistic.totalChairs +
                  Number.EPSILON) *
                  100
              ),
            })),
          }))
          .filter((item) => (value === "0" ? true : item.id === +value)) || [];
  }, [data, value, sections, t]);

  return (
    <SBMainComponentGridWrapper>
      <StatisticsReportsHeader />
      <SBFormGridWrapper>
        <Box height={"90px"} sx={{ gridColumnEnd: { lg: "span 2", sm: 1 } }}>
          <SBSelectListSubHeader
            name="sectionId"
            label={t("section")}
            noOptionLabel={t("NO_SECTION")}
            type={"edit"}
            icon={<GridOnIcon />}
            isLoading={false}
            handleChange={handleChange}
            defaultValue={"0"}
            option={value}
          >
            {sections?.items.map((section) => (
              <MenuItem
                key={`${section.name}-${section.id}`}
                data-testid={`${section.name}-${section.id}-option`}
                value={`${section.id}`}
              >
                {section.name}
              </MenuItem>
            ))}
          </SBSelectListSubHeader>
        </Box>
        {data &&
        totalChairs.length > 0 &&
        utilization.length > 0 &&
        chairs.length > 0 &&
        revenue.length > 0 ? (
          <>
            <SBColumnGridWrapper>
              <SBLabel label={`${t("dataChairs")}`} />
              <Box height="30vh" mt="10px">
                <Chart
                  options={{
                    data: chairs as any,
                    primaryAxis,
                    secondaryAxes,
                  }}
                />
              </Box>
            </SBColumnGridWrapper>
            <SBColumnGridWrapper>
              <SBLabel label={`${t("dataRevenue")}`} />
              <Box height="30vh" mt="10px">
                <Chart
                  options={{
                    data: revenue as any,
                    primaryAxis,
                    secondaryAxes,
                  }}
                />
              </Box>
            </SBColumnGridWrapper>
            <SBColumnGridWrapper>
              <SBLabel label={`${t("utilization")}`} />
              <Box height="30vh" mt="10px">
                <Chart
                  options={{
                    data: utilization as any,
                    primaryAxis,
                    secondaryAxes: secondaryAxesUtilization,
                  }}
                />
              </Box>
            </SBColumnGridWrapper>
            <SBColumnGridWrapper>
              <SBLabel label={t("dataTotalChairs")} />
              <Box height="30vh" mt="10px">
                <Chart
                  options={{
                    data: totalChairs as any,
                    primaryAxis,
                    secondaryAxes: secondaryAxesTotalChairs,
                  }}
                />
              </Box>
            </SBColumnGridWrapper>
          </>
        ) : (
          <SBCircularProgress />
        )}
      </SBFormGridWrapper>
    </SBMainComponentGridWrapper>
  );
};

export default StatisticsReports;
